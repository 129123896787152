import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { useState, useEffect } from "react";
import AddPatientsModal from "./components/AddPatientsModal";
import { Card, Divider, Heading, Authenticator } from "@aws-amplify/ui-react";
import SmallCard from "./components/SmallCard";
import LineChart from "./components/LineChart";
import { MONTHS } from "./Data";
import PieChart from "./components/PieChart";
import MB_logo_text from "./MB_logo_text.png";
import { Icon } from "@iconify/react";
import { Amplify, API } from "aws-amplify";

import awsExports from "./aws-exports";
import { listPatients } from "./graphql/queries";
Amplify.configure(awsExports);

const AVG_BEATS_PER_PATIENT = 810000;

function App({ signOut, user }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [patientList, setPatientList] = useState([]);

  const lineChartData = generateLineChartData(patientList);
  const pieChartData = generatePieChartData(patientList);
  const afibData = generateAfibData(patientList);
  const strokeData = generateStrokeData(afibData);

  function generateStrokeData(afibData) {
    const strokeRiskIfAfib = 0.012;
    return Math.round(afibData * strokeRiskIfAfib);
  }

  function generateAfibData(_patientList) {
    const afibPercent = 0.04;
    return Math.round(_patientList.length * afibPercent);
  }

  function generateLineChartData(_patientList) {
    if (!_patientList.length) return {};

    function getLabelFromDate(_date) {
      const month = _date.getMonth(); // 0 - 11
      const year = _date.getFullYear() % 100;
      return `${MONTHS[month]} '${year}`;
    }

    function getMonthDifference(startDate, endDate) {
      return (
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear())
      );
    }

    const firstDate = new Date(Date.parse(_patientList[0].dateAdded));
    const lastDate = new Date(
      Date.parse(_patientList[_patientList.length - 1].dateAdded)
    );
    // TODO: Fix + 1?
    const monthsBetween = getMonthDifference(firstDate, lastDate) + 1;

    let mappedData = [];

    // Create array of month/year
    // for every iteration, add + 1 to date to get next month/year
    let date = firstDate;
    for (let i = 0; i < monthsBetween; i++) {
      const label = getLabelFromDate(date);
      mappedData.push({ label, heartbeats: 0 });
      date = new Date(date.setMonth(date.getMonth() + 1));
    }

    let _totalHeartBeats = 0;
    for (let i = 0; i < mappedData.length; i++) {
      const entry = mappedData[i];

      const patientsForMonth = patientList.filter((patient) => {
        const dateAdded = new Date(Date.parse(patient.dateAdded));
        return entry.label === getLabelFromDate(dateAdded);
      });

      const heartBeats = patientsForMonth.length * AVG_BEATS_PER_PATIENT;
      entry.heartbeats = _totalHeartBeats + heartBeats;
      _totalHeartBeats = entry.heartbeats;
    }

    return {
      total: _totalHeartBeats,
      data: {
        labels: mappedData.map((entry) => entry.label),
        datasets: [
          {
            label: "Total Heartbeats",
            data: mappedData.map((entry) => entry.heartbeats),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgb(255, 99, 132)",
          },
        ],
      },
    };
  }

  function generatePieChartData(_patientList) {
    if (!_patientList.length) return {};

    const data = _patientList.reduce((_data, patient) => {
      const type = patient.type;

      if (_data[type]) return { ..._data, [type]: _data[type] + 1 };
      else return { ..._data, [type]: 1 };
    }, {});

    return {
      total: _patientList.length,
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: "Patients",
            data: Object.values(data),
            borderColor: ["rgb(255, 99, 132)", "rgb(119, 183, 242)"],
            backgroundColor: ["rgb(255, 99, 132)", "rgb(119, 183, 242)"],
          },
        ],
      },
    };
  }

  async function fetchAllPatients() {
    try {
      const res = await API.graphql({
        query: listPatients,
      });
      const patients = res.data.listPatients.items;
      let nextToken = res.data.listPatients.nextToken;
      // Check if there are more pages of events and fetch them if needed
      while (nextToken) {
        const nextPagePatientsData = await API.graphql({
          query: listPatients,
          variables: {
            nextToken: nextToken,
          },
        });
        patients.push(...nextPagePatientsData.data.listPatients.items);
        nextToken = nextPagePatientsData.data.listPatients.nextToken;
      }

      // Sort patients by dateAdded, oldest first
      patients.sort((a, b) => a.dateAdded.localeCompare(b.dateAdded));
      setPatientList(patients);
    } catch (err) {
      console.log("error fetching patients", err);
    }
  }

  // Move this to utils
  // Input space every third character 8000 -> 8 000
  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  useEffect(() => {
    fetchAllPatients();
  }, []);

  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      variation="modal"
    >
      <div className="App">
        <header>
          <div>
            <img className="logo" src={MB_logo_text} alt="Logo" />
          </div>
          <button
            className="icon-btn transition-opacity"
            onClick={() => setModalOpen(true)}
          >
            <Icon icon="mdi-light:settings" className="icon" />
          </button>
        </header>
        <main>
          <Card className="big-card" variation="elevated">
            <div>
              <Heading level={5} style={{ color: "gray" }}>
                Total Heartbeats
              </Heading>
              <Heading level={1}>
                {numberWithSpaces(lineChartData?.total || "")}
              </Heading>
              <Divider size="small" />
            </div>
            <div className="big-card-content">
              <LineChart data={lineChartData.data} />
            </div>
          </Card>
          <div className="small-cards">
            <SmallCard title="Afib" subTitle="Diagnosed">
              <h1>{afibData}</h1>
            </SmallCard>
            <SmallCard title="Strokes" subTitle="Prevented">
              <h1>{strokeData}</h1>
            </SmallCard>
            <SmallCard title={pieChartData.total || ""} subTitle="Patients">
              <PieChart data={pieChartData.data} />
            </SmallCard>
          </div>
        </main>
        <AddPatientsModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          updatePatientList={() => fetchAllPatients()}
        />
      </div>
    </Authenticator>
  );
}

export default App;
