import { useState } from "react";
import { MONTHS } from "../Data";
import Modal from "./Modal";
import { Grid, TextField, SelectField, Button } from "@aws-amplify/ui-react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { createPatient } from "../graphql/mutations";

const TYPES = [
  {
    label: "Clinic",
    value: "clinic",
  },
  {
    label: "Private",
    value: "private",
  },
];

function AddPatientsModal({ open, onClose, updatePatientList }) {
  const [noOfPatients, setNoOfPatients] = useState(0);
  const [type, setType] = useState("clinic");
  const [month, setMonth] = useState(MONTHS[0]);

  async function addPatients() {
    try {
      if (noOfPatients === 0) return;
      let monthToAdd = MONTHS.indexOf(month);

      const currentYear = new Date().getFullYear();
      const date = new Date(Date.UTC(currentYear, monthToAdd));
      const newPatient = {
        dateAdded: date.toISOString(),
        type: type,
      };

      for (let i = 0; i < noOfPatients; i++) {
        // Set date to first of "month"

        await API.graphql(
          graphqlOperation(createPatient, { input: newPatient })
        );
        console.log("Patient created: ", newPatient);
      }
      updatePatientList();
    } catch (err) {
      console.log("error creating patient: ", err);
    }
  }

  const onSubmit = (ev) => {
    ev.preventDefault(); // Prevent page reload

    console.log("NO OF PATIENTS", noOfPatients);
    console.log("TYPE", type);
    console.log("MONTH", month);

    addPatients();
    // Close modal if all went well
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Grid
          columnGap="1rem"
          rowGap="1rem"
          templateColumns="1fr 1fr 1fr"
          className="modal-content"
        >
          <TextField
            type="number"
            label="Number of patients"
            placeholder="Number of patients"
            value={noOfPatients}
            onChange={(ev) => setNoOfPatients(ev.target.value)}
          />
          <SelectField
            label="Type"
            value={type}
            onChange={(ev) => setType(ev.target.value)}
          >
            {TYPES.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </SelectField>
          <SelectField
            label="Month"
            value={month}
            onChange={(ev) => setMonth(ev.target.value)}
          >
            {MONTHS.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </SelectField>
        </Grid>
        <div className="modal-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button variation="primary" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default AddPatientsModal;
