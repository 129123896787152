import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function LineChart({ data }) {
  // TODO: Loading spinner if no data

  function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
  }

  return data ? (
    <Line
      data={data}
      options={{
        scales: {
          y: {
            ticks: {
              callback: (label) => formatCompactNumber(label),
              font: {
                size: 14,
              },
            },
          },
          x: {
            ticks: {
              font: {
                size: 14,
              },
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
      }}
    />
  ) : null;
}

export default LineChart;
