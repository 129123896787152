/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://suvml7ysc5dj3gt77xr3l3r7ii.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x62otwzyw5hd3btfxr5vtcbyoy",
    "aws_cognito_identity_pool_id": "eu-north-1:66262317-4c07-4d0d-84ef-dc93edb6c754",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_99AXK2r95",
    "aws_user_pools_web_client_id": "7p2u04cv8ujc08infnlcamn38j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
