import { Card, Divider, Heading } from "@aws-amplify/ui-react";
import "../App.css";

function SmallCard({ title, subTitle, children }) {
  return (
    <Card className="small-card" variation="elevated">
      <div>
        <Heading level={5} style={{ color: "gray" }}>
          {subTitle}
        </Heading>
        <Heading level={2}>{title}</Heading>
        <Divider size="small" />
      </div>
      <div className="small-card-content">{children}</div>
    </Card>
  );
}

export default SmallCard;
