import React, { useRef } from "react";
import { Card } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";

function Modal({ open, children, onClose }) {
  const overlay = useRef(null);

  const onCLickOverlay = (ev) => {
    if (ev.target === overlay.current) onClose();
  };

  return open ? (
    <div ref={overlay} className="modal-overlay" onClick={onCLickOverlay}>
      <Card className="modal">
        <div className="modal-header">
          <button className="icon-btn" onClick={onClose}>
            <Icon icon="mdi:close" className="icon"></Icon>
          </button>
        </div>
        {children}
      </Card>
    </div>
  ) : null;
}

export default Modal;
