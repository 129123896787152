import React from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, plugins } from "chart.js/auto";

function PieChart({ data }) {
  return data ? (
    <Doughnut
      data={data}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            align: "center",
            labels: {
              font: { size: 14 },
            },
          },
        },
      }}
    />
  ) : null;
}

export default PieChart;
